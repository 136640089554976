// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyC9M8jmtGYkRcICPOqDKRWcmcGbRs1h5Gs",
  authDomain: "gasbillnew3.firebaseapp.com",
  databaseURL: "https://gasbillnew3-default-rtdb.firebaseio.com",
  projectId: "gasbillnew3",
  storageBucket: "gasbillnew3.appspot.com",
  messagingSenderId: "489274569317",
  appId: "1:489274569317:web:7c11da6a8c12c2d78e4c8c",
  measurementId: "G-QT5KL1Z7L7"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };